import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
// import FAQ from "./components/FAQ";
import Graphic from "./components/Graphic";
import Headline from "./components/Headline";
import Navbar from "./components/Navbar";

function App() {
  return (
    <div>
      <Navbar />
      <Headline />
      <About />
      <Graphic />
      {/* <FAQ /> */}
      <Contact />
      <Footer />
    </div>
  );
}

export default App;