import React, { useState } from "react";
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';  // Import Firestore instance

const Contact = () => {
    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Add document to 'users' collection in Firestore
            await addDoc(collection(db, 'Contact'), {
                email: email,
                subject: subject,
                message: message,
                // timestamp: new Date(),  // Optional: Add a timestamp field
            });
            alert('Document successfully added!');
        } catch (error) {
            console.error('Error adding document: ', error);
            alert('Failed to add document.');
        }

        // Reset the form after submission
        setEmail("");
        setSubject("");
        setMessage("");
    };

    const[email, setEmail] = useState("")
    const[subject, setSubject] = useState("")
    const[message, setMessage] = useState("")

    return (
        <section class="bg-gray-900">
            <div class="py-8 lg:py-[160px] px-4 mx-auto max-w-screen-md">
                <h2 class="mb-4 text-4xl lg:text-5xl tracking-tight font-extrabold text-center text-white">Contact Us</h2>
                <p class="mb-8 lg:mb-16 font-light text-center text-gray-400 sm:text-xl">Got another question? Got a technical issue? Want to send feedback about a beta feature? Let us know.</p>
                <form action="#" class="space-y-8">
                    <div>
                        <label for="email" class="block mb-2 text-sm font-medium text-gray-300">Your email</label>
                        <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} class="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500 shadow-sm-light" placeholder="name@email.com" required></input>
                    </div>
                    <div>
                        <label for="subject" class="block mb-2 text-sm font-medium text-gray-300">Subject</label>
                        <input type="text" id="subject" value={subject} onChange={(e) => setSubject(e.target.value)} class="block p-3 w-full text-sm rounded-lg border shadow-sm bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500 shadow-sm-light" placeholder="Let us know how we can help you..." required></input>
                    </div>
                    <div class="sm:col-span-2">
                        <label for="message" class="block mb-2 text-sm font-medium text-gray-400">Your message</label>
                        <textarea id="message" rows="6" value={message} onChange={(e) => setMessage(e.target.value)} class="block p-2.5 w-full text-sm rounded-lg shadow-sm border bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500" placeholder="Your message here..."></textarea>
                    </div>
                    <button type="submit" onClick={handleSubmit} class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg sm:w-fit focus:outline-none bg-primary-600 hover:bg-primary-700 focus:ring-primary-800">Send message</button>
                </form>
            </div>
        </section>
    )
}

export default Contact