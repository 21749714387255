import React from "react";
import videoTimelapse from "../assets/video/timelapse.mp4";

const Graphic = () => {
    return (
        <div className="relative text-center object-cover">
            <video src={videoTimelapse} className="w-full relative overflow-hidden" autoplay="autoplay" loop muted playsInline />
            <h1 className="absolute bg-black top-0 left-0 text-white font-extrabold text-[25vw] resize-x font-sans leading-tight mix-blend-multiply">Cloud Point</h1>
            <div className="bg-gray-900 h-[6vh]"></div>
        </div>
    )
}

export default Graphic