import React, { useState } from "react";
import VidComp from './Video';

const Headline = () => {
    return (
        <div className="w-full py-[75px]">
            <div className="px-10 sm:px-20 md:px-20 lg:px-20 xl:px-20 2xl:px-20 lg:flex items-center w-full">
                <div>
                    <h1 className="text-white font-extrabold pt-6 text-6xl lg:text-7xl">
                        Changing Climate Science Through Learning.
                    </h1>
                    <p className="text-white text-xl md:text-2xl lg:text-3xl py-6">
                        Capture, Identify, Share, Learn.
                    </p>
                    <p className="text-white text-xl md:text-2xl lg:text-3xl">
                        A platform for all things cloud, right in your pocket.
                    </p>
                    <div className="w-[50vw] sm:w-[350px] md:w-[350px] lg:w-[350px] xl:w-[350px] 2xl:w-[350px]">
                        <a href="https://apps.apple.com/us/app/cloud-point/id6480587789">
                            <img className="hover:shadow-2xl"
                                src={require("../assets/images/apple_image.png")} alt="apple install image"
                            />
                        </a>
                    </div>
                    <button onClick={() => alert("Android coming very soon!")}>
                        <img className="w-[50vw] sm:w-[350px] md:w-[350px] lg:w-[350px] xl:w-[350px] 2xl:w-[350px] hover:shadow-2xl"
                            src={require("../assets/images/google_image.png")} alt="google install image"
                        />
                    </button>
                </div>
                <div className="w-full lg:w-[1000px] sm:px-[75px] md:px-[150px] py-4">
                    <VidComp />
                </div>
            </div>
            <div className="flex items-center justify-center space-x-3 pt-10">
                <p className="text-white text-2xl">Find us on</p>
                <a href="https://x.com/solodevdude">
                    <img className="h-12 hover:shadow-2xl"
                        src={require("../assets/images/x_logo.png")} alt="X logo"
                    />
                </a>
                <a href="https://www.instagram.com/cloudpointofficial/?utm_source=qr">
                    <img className="h-12 hover:shadow-2xl"
                        src={require("../assets/images/insta_logo.png")} alt="Instagram logo"
                    />
                </a>
                <a href="https://www.youtube.com/@cloud-point">
                    <img className="h-12 hover:shadow-2xl"
                        src={require("../assets/images/YT_logo.png")} alt="YouTube logo"
                    />
                </a>
            </div>
        </div>
    )
}

export default Headline