import React, { userRef, useState, useEffect } from 'react';
import Loading from './Loading';
import video1 from "../assets/video/new_vid.mp4";

function VidComp() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate an API call
        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <video src={video1} className="rounded-lg shadow-xl relative overflow-hidden" autoplay="autoplay" controls loop muted playsinline/> 
    );
}
export default VidComp;