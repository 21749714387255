import React from 'react';
import { grid } from 'ldrs'

function Loading() {
  grid.register()
  return (
    <div className=' text-center items-center'>
      <l-grid
        size="120"
        speed="1.5"
        color="white"
      ></l-grid>
      <h2 className='text-white font-bold text-xl pt-1'>Loading Video...</h2>
    </div>
  );
}

export default Loading;